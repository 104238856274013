import Pako from "pako";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { UniqueIdRecord, getUniqueIdMap, seedAll } from "../../../../common/generateUniqueId";
import { env } from "../../../../env/env";

import { EditorState, loadEditorState, resetEditor } from "../../../editor/editorSlice";
import { CommitRef, fetchLogicsConfiguration, resetLogics, selectFetchLogicsStatus } from "../../../logics/logicsSlice";
import { createWindow, resetWindowManager } from "../../../windowManager/windowManagerSlice";
import { closeMenu, resetMenuManager } from "../../menuManagerSlice";
import { MenuContent, menuItem, menuSeparator } from "../Menu";

import SaveFormat from '../../../../common/save/SaveFormat'
import Version from '../../../../common/Version'

export type ProjectMenuType = "project";

export const saveVersion: Version = {
    commit: env.frontendVersion + env.branch,
    major: 1,
    minor: 0,
    patch: 0,
};

function loadProject(cb: (saveFormat: SaveFormat) => void) {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".ppf";

    input.onchange = event => {
        const file = (event.target as HTMLInputElement).files?.[0];

        if (!file) {
            console.warn(`Error reading file.`);
            return;
        }

        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = event => {
            const content = event.target?.result;

            if (!content) {
                console.warn(`Error reading file contents.`);
                return;
            }

            if (typeof content === "string") {
                console.warn(`File content read as string.`);
                return;
            }

            const json = Pako.inflate(new Uint8Array(content), { to: "string" });
            const saveFormat = JSON.parse(json) as SaveFormat;
            saveFormat.editor.blockPreview = null;
            console.log({saveFormat});

            cb(saveFormat);
        };
    }

    input.click();
}

export function ProjectMenu() {
    const dispatch = useAppDispatch();
    const logicsStatus = useAppSelector(selectFetchLogicsStatus);
    const editor = useAppSelector(state => state.editor);

    return <MenuContent
        parts={[
            menuItem({
                label: "New",
                effect: () => {
                    dispatch(closeMenu());
                    console.log("New");
                    const windowSize = { x: 400, y: 160};
                    dispatch(createWindow({
                        title: "New project confirmation",
                        size: windowSize,
                        position: {
                            x: (window.innerWidth - windowSize.x) / 2,
                            y: (window.innerHeight - windowSize.y) / 2,
                        },
                        typeAndProps: {
                            type: "confirmNew",
                            props: {},
                        }
                    }));
                },
                disabled: logicsStatus.code !== "succeeded"
            }),
            menuItem({
                label: "Load",
                effect: () => {
                    dispatch(closeMenu());
                    console.log("Load");
                    loadProject(saveFormat => {
                        dispatch(resetEditor());
                        dispatch(resetWindowManager());
                        dispatch(resetMenuManager());
                        dispatch(resetLogics());

                        dispatch(fetchLogicsConfiguration(saveFormat.logicsCommitRef));
                        dispatch(loadEditorState(saveFormat.editor));
                        seedAll(saveFormat.idMap);
                    });
                },
            }),
            menuItem({
                label: "Save",
                effect: () => {
                    dispatch(closeMenu());
                    console.log("Save");

                    if (logicsStatus.code !== "succeeded") {
                        throw new Error("Logics not loaded");
                    }

                    const saveFormat: SaveFormat = {
                        logicsCommitRef: logicsStatus.logics.commit,
                        editor,
                        saveVersion,
                        idMap: getUniqueIdMap(),
                    };

                    const json = JSON.stringify(saveFormat);
                    const compressed = Pako.deflate(json);

                    function download(content: any, fileName: string, contentType: string) {
                        let a = document.createElement("a");
                        let file = new Blob([content], { type: contentType });
                        a.href = URL.createObjectURL(file);
                        a.download = fileName;
                        a.click();
                    }

                    download(compressed, `${editor.projectName}.ppf`, "blob");
                },
                disabled: logicsStatus.code !== "succeeded"
            }),
            menuItem({
                label: "Export to JSON",
                effect: () => {
                    dispatch(closeMenu());
                    console.log("Save");

                    if (logicsStatus.code !== "succeeded") {
                        throw new Error("Logics not loaded");
                    }

                    const saveFormat: SaveFormat = {
                        logicsCommitRef: logicsStatus.logics.commit,
                        editor,
                        saveVersion,
                        idMap: getUniqueIdMap(),
                    };

                    const json = JSON.stringify(saveFormat);

                    function download(content: any, fileName: string, contentType: string) {
                        let a = document.createElement("a");
                        let file = new Blob([content], { type: contentType });
                        a.href = URL.createObjectURL(file);
                        a.download = fileName;
                        a.click();
                    }

                    download(json, `${editor.projectName}.json`, "blob");
                },
                disabled: logicsStatus.code !== "succeeded"
            }),
            menuSeparator(),
            menuItem({
                label: "Migrate logics version",
                effect: () => {
                    dispatch(closeMenu());

                    const windowSize = { x: 500, y: 160};

                    dispatch(createWindow({
                        title: "Logics migration",
                        size: windowSize,
                        position: {
                            x: (window.innerWidth - windowSize.x) / 2,
                            y: (window.innerHeight - windowSize.y) / 2,
                        },
                        typeAndProps: {
                            type: "logicsMigration",
                            props: {},
                        }
                    }));
                },
                disabled: logicsStatus.code !== "succeeded"
            }),
        ]}
    />;
}
