import { useAppDispatch, useAppSelector } from "../../app/hooks";
import css from "./WindowManager.module.css";
import { TypedWindow } from "./window/TypedWindow";
import { closeWindow, selectWindows } from "./windowManagerSlice";

export function WindowManager() {
    const dispatch = useAppDispatch();
    const windows = useAppSelector(selectWindows);
    const windowKeys = Object.keys(windows);

    return <div className={css.windowManager} onClick={() => console.log("fooooo")}>
        {windowKeys.length > 0 && <div className={css.windowManagerBackground} onClick={() => dispatch(closeWindow(windowKeys[0]))}/>}
        {windowKeys.map(id => <TypedWindow key={id} id={id}/>)}
    </div>;
}
