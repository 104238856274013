import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Ref, forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { zip } from "../../../../../common/zip";
import { updateBlockDataValue, updateBlockVariableContent, updateMemoryRefSource, updateOperatorEnabledIos } from "../../../../editor/editorSlice";
import Memory from "../../../../editor/interfaces/components/Memory";
import Operator from "../../../../editor/interfaces/components/Operator";
import { Types, VariableContent } from "../../../../editor/interfaces/components/Variable";
import { LogicsMemory } from "../../../../logics/logics/LogicsMemory";
import { LogicsOperator } from "../../../../logics/logics/LogicsOperator";
import { LogicsData as DataConfig, LogicsTypes as TypesConfig, LogicsVariable as VariableConfig } from "../../../../logics/logics/LogicsVariable";
import { selectLogics } from "../../../../logics/logicsSlice";
import { WindowTypeAndPropsDefinition, closeWindow, resizeWindowByOffset, selectWindow } from "../../../windowManagerSlice";
import css from "./BlockConfigWindow.module.css";

interface Saveable {
    saveVariables: () => void;
}

interface BlockSourceOperator {
    type: "operator";
    operatorId: string;
    sheetIndex: number;
}

interface BlockSourceMemory {
    type: "memoryRef";
    memoryRefId: string;
    sheetIndex: number;
}

export type BlockSource = BlockSourceOperator | BlockSourceMemory;

export interface BlockConfigWindowProps {
    source: BlockSource;
}

export type BlockConfigWindowTypeAndProps = WindowTypeAndPropsDefinition<
    "blockConfig",
    BlockConfigWindowProps
>;

function OperatorConfigWindow(props: BlockSourceOperator & { id: string }) {
    const dispatch = useAppDispatch();
    const logics = useAppSelector(selectLogics);
    const operator = useAppSelector(
        state => state.editor.sheets[props.sheetIndex].operators.find(
            operator => operator.id === props.operatorId
        )
    );
    const window = useAppSelector(selectWindow(props.id));

    const [canSaveVariables, setCanSaveVariables] = useState(false);
    const [canSaveData, setCanSaveData] = useState(false);
    const [canSaveIoEnables, setCanSaveIoEnables] = useState(false);

    const [hasVariablesChange, setHasVariablesChange] = useState(false);
    const [hasDataChange, setHasDataChange] = useState(false);
    const [hasIoEnablesChange, setHasIoEnablesChange] = useState(false);

    const variablesRef = useRef<Saveable>(null);
    const dataRef = useRef<Saveable>(null);
    const ioEnablesRef = useRef<Saveable>(null);

    const contentRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (!contentRef.current) {
            return;
        }

        console.log({
            content: contentRef.current.scrollHeight,
            window: window.size.y,
        });
        if (contentRef.current.scrollHeight > window.size.y) {
            console.log(contentRef.current.scrollHeight - window.size.y);
            dispatch(resizeWindowByOffset({
                windowId: props.id,
                delta: {
                    x: 0,
                    y: contentRef.current.scrollHeight - window.size.y + 16,
                }
            }));
        }
    }, []);

    if (!operator) {
        dispatch(closeWindow(props.id));
        return null;
    }

    const config = logics.operator[operator.name];

    const canSave = canSaveVariables && canSaveData && canSaveIoEnables;
    const hasChange = hasVariablesChange || hasDataChange || hasIoEnablesChange;

    return <div className={css.content} ref={contentRef}>
        <BlockConfigWindowVariables
            ref={variablesRef}
            source={props}
            block={operator}
            config={config}
            setCanSave={setCanSaveVariables}
            setHasChange={setHasVariablesChange}
        />
        <BlockConfigWindowData
            ref={dataRef}
            source={props}
            block={operator}
            config={config}
            setCanSave={setCanSaveData}
            setHasChange={setHasDataChange}
        />
        <BlockConfigWindowIoEnables
            ref={ioEnablesRef}
            source={props}
            operator={operator}
            logicsOperator={config}
            setCanSave={setCanSaveIoEnables}
            setHasChange={setHasIoEnablesChange}
        />
        <button
            className={css.saveButton}
            disabled={!(canSave && hasChange)}
            onClick={() => {
                variablesRef.current?.saveVariables();
                dataRef.current?.saveVariables();
                ioEnablesRef.current?.saveVariables();
            }}
            style={{
                position: "absolute",
                right: 55,
                top: 2,
            }}
        ><FontAwesomeIcon icon={faFloppyDisk}/></button>
    </div>;
}

function MemoryRefConfigWindow(props: BlockSourceMemory & { id: string }) {
    const dispatch = useAppDispatch();

    const logics = useAppSelector(selectLogics);
    const memories = useAppSelector(state => state.editor.memories);
    const memoryRef = useAppSelector(
        state => state.editor.sheets[props.sheetIndex].memoryRefs.find(
            memoryRef => memoryRef.id === props.memoryRefId
        )
    );

    const [canSaveVariables, setCanSaveVariables] = useState(false);
    const [canSaveData, setCanSaveData] = useState(false);
    const [hasVariablesChange, setHasVariablesChange] = useState(false);
    const [hasDataChange, setHasDataChange] = useState(false);

    const variablesRef = useRef<Saveable>(null);
    const dataRef = useRef<Saveable>(null);

    if (!memoryRef) {
        dispatch(closeWindow(props.id));
        return null;
    }

    const config = logics.memory[memoryRef.name];

    const memory = useAppSelector(
        state => state.editor.memories[memoryRef.name].find(
            memory => memory.id === memoryRef.sourceMemoryId
        )
    );

    console.log({memory});

    // const isIo = ["INPUT", "OUTPUT"].includes(config.name);

    useEffect(() => {
        console.log("change");
    }, [variablesRef.current, dataRef.current]);

    const canSave = canSaveVariables && canSaveData;
    const hasChange = hasVariablesChange || hasDataChange;

    useEffect(() => {
        console.log({canSave, hasChange, disabled: !(canSave && hasChange)});
    }, [canSave, hasChange]);

    return <div className={css.content}>
        <div className={css.row}>
            <div className={css.sectionTitle}>Memory</div>
            <select
                value={memory?.label ?? ""}
                onChange={event => {
                    const associatedMemory = memories[memoryRef.name].find(memory => memory.label === event.target.value);
                    console.log(associatedMemory, memories[memoryRef.name]);
                    if (associatedMemory) {
                        dispatch(updateMemoryRefSource({
                            sheetId: memoryRef.sheetId,
                            memoryRefId: memoryRef.id,
                            sourceMemoryId: associatedMemory.id,
                        }));
                    }
                }}
            >
                {memory === undefined && <option value={""}/>}
                {memories[config.name].map(memory => {
                    return <option key={memory.label} value={memory.label}>
                        {/* {memory.source.type === "io" ? `${memory.label} (${memory.source.io})` : memory.label} */}
                        {memory.label}
                    </option>;
                })}
            </select>
        </div>
        {memory && <React.Fragment key={memory.label}>
            <BlockConfigWindowVariables
                ref={variablesRef}
                source={props}
                block={memory}
                config={config}
                setCanSave={setCanSaveVariables}
                setHasChange={setHasVariablesChange}
            />
            <BlockConfigWindowData
                ref={dataRef}
                source={props}
                block={memory}
                config={config}
                setCanSave={setCanSaveData}
                setHasChange={setHasDataChange}
            />
            <button
                className={css.saveButton}
                disabled={!(canSave && hasChange)}
                onClick={() => {
                    variablesRef.current?.saveVariables();
                    dataRef.current?.saveVariables();
                }}
                style={{
                    position: "absolute",
                    right: 55,
                    top: 2,
                }}
            ><FontAwesomeIcon icon={faFloppyDisk}/></button>
        </React.Fragment>}
    </div>;
}

const BlockConfigWindowVariables = forwardRef((props: {
    source: BlockSource,
    block: Operator | Memory,
    config: LogicsOperator | LogicsMemory,
    setCanSave: (canSave: boolean) => void,
    setHasChange: (hasChange: boolean) => void,
}, ref: Ref<Saveable>) => {
    const { source, block, config } = props;

    const dispatch = useAppDispatch();

    const nonPrivateVariables = config.variables.filter(variable => !variable.private);
    const [variables, setVariables] = useState(block.metadata.variables);
    const setVariableContent = (name: string) => {
        return (content: VariableContent) => setVariables(variables => {
            return {
                ...variables,
                [name]: {
                    ...variables[name],
                    content,
                },
            };
        });
    };

    const hasVariableChanges = useMemo(() => {
        return Object.entries(variables)
        .some(([name, variable]) => {
            const originalJson = JSON.stringify(block.metadata.variables[name].content);
            const newJson = JSON.stringify(variable.content);
            return originalJson !== newJson;
        });
    }, [variables]);

    const areVariablesValid = useMemo(() => {
        return Object.entries(variables)
            .every(([_name, variable]) => {
                switch (variable.content.type) {
                    case "boolean":
                    case "enum":
                        return true;
                    case "number":
                        return !isNaN(variable.content.value);
                }
            });
    }, [variables]);

    useEffect(() => {
        props.setCanSave(areVariablesValid);
        props.setHasChange(hasVariableChanges);
        console.log({hasVariableChanges, areVariablesValid});
    }, [hasVariableChanges, areVariablesValid]);

    useImperativeHandle(ref, () => ({
        saveVariables: () => {
            Object.entries(variables).forEach(([name, variable]) => {
                dispatch(updateBlockVariableContent({
                    source,
                    variableName: name,
                    content: variable.content,
                }));
                setVariableContent(name)(variable.content);
            });
        },
    }), [variables, props]);

    if (nonPrivateVariables.length === 0) {
        return null;
    }

    return <>
        <div className={css.row}>
            <div className={css.sectionTitle}>Variables</div>
            <div className={css.sectionTitle}>

            </div>
        </div>
        {nonPrivateVariables.map((config, index) => {
            const variable = variables[config.name];

            return <div key={index} className={css.row}>
                <div className={css.label}>
                    {variable.label}
                </div>
                <React.Fragment key={index}>
                    {variable.content.type === "boolean" && <BooleanVariable
                        content={variable.content}
                        config={config}
                        onChange={setVariableContent(config.name)}
                    />}
                    {variable.content.type === "enum" && <EnumVariable
                        content={variable.content}
                        config={config}
                        onChange={setVariableContent(config.name)}
                    />}
                    {variable.content.type === "number" && <NumberVariable
                        content={variable.content}
                        config={config}
                        onChange={setVariableContent(config.name)}
                    />}
                </React.Fragment>
            </div>;
        })}
    </>;
});

const BlockConfigWindowIoEnables = forwardRef((props: {
    source: BlockSource,
    operator: Operator,
    logicsOperator: LogicsOperator,
    setCanSave: (canSave: boolean) => void,
    setHasChange: (hasChange: boolean) => void,
}, ref: Ref<Saveable>) => {
    const { source, operator, logicsOperator } = props;

    const dispatch = useAppDispatch();

    const inputs = operator.inputs;
    const logicsInputs = logicsOperator.inputs;
    const nonRequiredInputs = logicsInputs.filter(input => !input.required);

    const [inputEnables, setInputEnables] = useState(() => Object.fromEntries(
        inputs.map(io => [io.name, io.enabled])
    ));

    const outputs = operator.outputs;
    const logicsOutputs = logicsOperator.outputs;
    const nonRequiredOutputs = logicsOutputs.filter(output => !output.required);

    const [outputEnables, setOutputEnables] = useState(() => Object.fromEntries(
        outputs.map(io => [io.name, io.enabled])
    ));

    const setEnable = (name: string, ioType: "input" | "output") => {
        return (enabled: Types.Boolean) => {
            switch (ioType) {
                case "input":
                    setInputEnables(enables => {
                        return {
                            ...enables,
                            [name]: enabled.value,
                        };
                    });
                    break;

                case "output":
                    setOutputEnables(enables => {
                        return {
                            ...enables,
                            [name]: enabled.value,
                        };
                    });
                    break;
            }
        }
    };

    const hasVariableChanges = useMemo(() => {
        return zip(Object.values(inputEnables), inputs.map(io => io.enabled))
            .concat(zip(Object.values(outputEnables), outputs.map(io => io.enabled)))
            .some(([a, b]) => a !== b);
    }, [inputEnables, outputEnables, props]);

    useEffect(() => {
        props.setCanSave(true);
        props.setHasChange(hasVariableChanges);
        console.log({hasVariableChanges});
    }, [hasVariableChanges]);

    useImperativeHandle(ref, () => ({
        saveVariables: () => {
            Object.entries(inputEnables).forEach(([name, enabled]) => {
                dispatch(updateOperatorEnabledIos({
                    sheetIndex: source.sheetIndex,
                    operatorId: operator.id,
                    ioType: "input",
                    ioName: name,
                    enabled,
                }));
            });
            Object.entries(outputEnables).forEach(([name, enabled]) => {
                dispatch(updateOperatorEnabledIos({
                    sheetIndex: source.sheetIndex,
                    operatorId: operator.id,
                    ioType: "output",
                    ioName: name,
                    enabled,
                }));
            });
        },
    }), [inputEnables, outputEnables, props]);

    if (nonRequiredInputs.length === 0 && nonRequiredOutputs.length === 0) {
        return null;
    }

    return <>
        <div className={css.row}>
            <div className={css.sectionTitle}>IO group configuration</div>
            <div className={css.sectionTitle}>
            </div>
        </div>
        {nonRequiredInputs.map(logicsInput => {
            const enabled = inputEnables[logicsInput.label];

            return <div key={logicsInput.label} className={css.row}>
                <div className={css.label}>
                    Enable {logicsInput.label}
                </div>
                <BooleanVariable
                    content={{
                        type: "boolean",
                        value: enabled,
                    }}
                    onChange={setEnable(logicsInput.label, "input")}
                />
            </div>;
        })}
        {nonRequiredOutputs.map(logicsOutput => {
            const enabled = outputEnables[logicsOutput.label];

            return <div key={logicsOutput.label} className={css.row}>
                <div className={css.label}>
                    Enable {logicsOutput.label}
                </div>
                <BooleanVariable
                    content={{
                        type: "boolean",
                        value: enabled,
                    }}
                    onChange={setEnable(logicsOutput.label, "output")}
                />
            </div>;
        })}
    </>;
});

const BlockConfigWindowData = forwardRef((props: {
    source: BlockSource,
    block: Operator | Memory,
    config: LogicsOperator | LogicsMemory,
    setCanSave: (canSave: boolean) => void,
    setHasChange: (hasChange: boolean) => void,
}, ref: Ref<Saveable>) => {
    const { source, block, config } = props;

    const dispatch = useAppDispatch();

    const [data, setData] = useState(block.metadata.data);
    const setDataContent = (name: string) => {
        return (content: string) => setData(data => {
            console.log({name, content});
            return {
                ...data,
                [name]: content,
            };
        });
    };

    const hasDataChanges = useMemo(() => {
        const a = Object.entries(data)
            .some(([name, value]) => {
                console.log({name, value, data: block.metadata.data[name]});
                console.log(block.metadata.data[name] !== value);
                return block.metadata.data[name] !== value;
            });
        console.log(a);
        return a;
    }, [data, block.metadata.data]);

    const areDataValid = useMemo(() => {
        return Object.entries(data)
            .every(([_name, value]) => {
                const isAscii = /^[\x00-\x7F]*$/.test(value);
                return isAscii;
            });
    }, [data]);

    useEffect(() => {
        props.setCanSave(areDataValid);
        props.setHasChange(hasDataChanges);
        console.log({hasDataChanges, areDataValid});
    }, [hasDataChanges, areDataValid]);

    useImperativeHandle(ref, () => ({
        saveVariables: () => {
            Object.entries(data).forEach(([name, value]) => {
                console.log("save", {name, value});
                dispatch(updateBlockDataValue({
                    source,
                    dataName: name,
                    value,
                }));
                dispatch(updateBlockVariableContent({
                    source,
                    variableName: name,
                    content: {
                        type: "number",
                        value: value.length,
                    },
                }));
                // setDataContent(name)(value);
            });
        },
    }), [data, props]);

    if (!config.data || Object.keys(config.data).length === 0) {
        return null;
    }

    return <>
        <div className={css.row}>
            <div className={css.sectionTitle}>Data</div>
            <div className={css.sectionTitle}>
            </div>
        </div>
        {config.data.map((config, index) => {
            const currentData = data[config.size];

            return <div key={index} className={css.row}>
                <div className={css.label}>
                    {config.label}
                </div>
                <DataField
                    content={currentData}
                    config={config}
                    onChange={setDataContent(config.size)}
                />
            </div>;
        })}
    </>;
});

export function BlockConfigWindow(props: BlockConfigWindowProps & { id: string }) {
    switch (props.source.type) {
        case "operator": return <OperatorConfigWindow {...props.source} id={props.id}/>;
        case "memoryRef": return <MemoryRefConfigWindow {...props.source} id={props.id}/>;
    }
}

export function DataField(props: {
    content: string,
    config: DataConfig,
    onChange: (value: string) => void,
    maximumSize?: number,
}) {
    return <div className={css.dataField}>
        <input
            type="text"
            value={props.content}
            maxLength={props.maximumSize}
            onChange={event => {
                props.onChange(event.target.value);
            }}
        />
    </div>;
}

export function BooleanVariable(props: {
    content: Types.Boolean,
    config?: VariableConfig,
    onChange: (value: Types.Boolean) => void,
}) {
    return <div className={css.booleanVariableInput}>
        <input
            type="checkbox"
            checked={props.content.value}
            onChange={event => {
                props.onChange({
                    ...props.content,
                    value: event.target.checked,
                });
            }}
        />
    </div>;
}

export function EnumVariable(props: {
    content: Types.Enum,
    config: VariableConfig,
    onChange: (value: Types.Enum) => void,
}) {
    const content = props.config.content as TypesConfig.LogicsEnum;

    return <div className={css.enumVariableInput}>
        <select
            value={props.content.value}
            onChange={event => {
                props.onChange({
                    ...props.content,
                    value: event.target.value,
                });
            }}
        >
            {Object.keys(content.options).map(key => {
                return <option value={key}>{key}</option>;
            })}
        </select>
    </div>;
}

export function NumberVariable(props: {
    content: Types.Number,
    config: VariableConfig,
    onChange: (value: Types.Number) => void,
}) {
    const content = props.config.content as TypesConfig.LogicsNumber;

    return <div className={css.numberVariableInput}>
        <input
            className={css.numberVariableInput}
            type="text"
            min={content.min}
            max={content.max}
            value={isNaN(props.content.value) ? "" : props.content.value}
            onChange={event => {
                let newValue = parseInt(event.target.value.replace(/[^0-9]/g, ""));

                const isNegative = event.target.value.startsWith("-");
                if (isNegative) {
                    newValue *= -1;
                }

                const nativeEvent = (event.nativeEvent as InputEvent);
                if (nativeEvent.data === "-") {
                    newValue *= -1;
                }

                // if (content.min !== undefined) {
                //     newValue = Math.max(newValue, content.min);
                // }

                // if (content.max !== undefined) {
                //     newValue = Math.min(newValue, content.max);
                // }

                props.onChange({
                    ...props.content,
                    value: newValue,
                });
            }}

        />
    </div>;
}
